import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/styles';
import Layout from '../components/Layout/Layout';
import activeHtml from 'react-active-html';
import CardMedia from '@material-ui/core/CardMedia';
import makeContentComponentsMap from '../util/makeContentComponentsMap';

if (typeof window === 'undefined') {
  const xmldom = require('xmldom');
  activeHtml.DOMParser = new xmldom.DOMParser();
}

function Post(props) {
  const componentsMap = makeContentComponentsMap();

  const wysiwygNodes = activeHtml(
    props.pageContext.data.post.content.rendered,
    componentsMap
  );

  return (
    <Layout
      settings={props.pageContext.data.settings}
      accounts={props.pageContext.data.myAccounts}
      title={props.pageContext.data.post.title.rendered}
      location={props.location}
    >
      <Container>
        <Content>
          <Typography mb={3} paragraph variant="h6">
            {props.pageContext.data.post.title.rendered}
          </Typography>
          {/* <CardMedia
        src={imageUrl}
        className={classes.featuredMedia}
        component="img"
      /> */}
          {wysiwygNodes}
        </Content>
      </Container>
    </Layout>
  );
}

Post.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Container = styled('div')(({ theme }) => ({
  padding: `0 ${theme.spacing(3)}px`,
  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(6)}px`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: `0 ${theme.spacing(8)}px`,
  },
}));

const Content = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: '0 auto',
}));

const FeaturedMedia = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
}));

export default Post;
